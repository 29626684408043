import React from 'react';

import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from "../components/layout"
import Seo from "../components/seo"

const MissionPage = () => {
    const data = useStaticQuery(graphql`
        query MissionPage {
            datoCmsMission {
                heading
                missionStatementNode {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    `);

    const { datoCmsMission } = data;

    const { heading } = datoCmsMission;
    const { html } = datoCmsMission.missionStatementNode.childMarkdownRemark;
    return (
        <Layout image={false} header={false}>
          <Grid
              container
              direction="column"
              rowSpacing={4}
              sx={{
                  marginTop: '0px',
                  backgroundColor: 'rgba(255, 255, 255, .8)',
              }}
          >
            <Grid item xs={12}>
                <Typography variant="h5">{heading.toUpperCase()}</Typography>
            </Grid>
            <Grid item xs={12}>
              <p dangerouslySetInnerHTML={{ __html: html }}/>
            </Grid>
          </Grid>
        </Layout>
    );
}

export default MissionPage
